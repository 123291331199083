import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@molecules';
import { Content, SimpleHero } from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentNewsItemTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent,
    disableAlertBar, enLanguagePageLinks, pageInOtherLanguages,
  } = page || {};
  const {
    title, introduction, content, heroImage, publicationDate,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme="transparent"
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
      footerBackground="white"
      heroLogo="lightblue"
    >
      <SimpleHero
        title={title}
        heroColor="lightblue"
        intro={heroImage ? "" : introduction}
        images={heroImage ? [heroImage] : []}
        date={publicationDate}
      />
      <Content
        title={(heroImage) ? title : ""}
        intro={(heroImage) ? introduction : ""}
        body={content}
        pageType="contentDetail"
        date={publicationDate}
      />
    </Layout>
  );
};

export default ContentNewsItemTemplate;

export const newsItemPageQuery = graphql`
  query ContentNewsItemBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentNewsItem {
          title
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH, backgroundColor: "#091f2f", quality: 100)
            description
            contentful_id
          }
          publicationDate(formatString: "MMM D, YYYY")
          introduction {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulPage {
                path
                contentful_id
              }
            }
          }
          attribution
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulComponentAppointee {
                id
                lastName
                firstName
                contentful_id
                headshot {
                  gatsbyImageData
                }
                internal {
                  type
                }
              }
              ... on ContentfulComponentPullQuote {
                contentful_id
                citationText
                quote {
                  raw
                }
                internal {
                  type
                }
              }
              ... on ContentfulComponentMediaEmbed {
                id
                contentful_id
                embedType
                caption {
                  raw
                }
                image {
                  gatsbyImageData
                }
                internal {
                  type
                }
              }
              ... on ContentfulPage {
                path
                contentful_id
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
